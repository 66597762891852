$(function() {
	$window = $(window);
	
	$(".scrolldown-middle").click(function() {
		$('html,body').animate({scrollTop: $("#color-section").offset().top - 50},'slow');
    }); 
	
	$(".scrolldown-bottom").click(function() {
		$('html,body').animate({scrollTop: $("#color-section").offset().top - 50},'slow');
    });
	
	$("#search").hover(
		function(){
			$(this).css({"padding-left": "300px"});
        	$(".searchinput").delay(200).fadeIn(500);
		},
	 	function(){
			$(this).css({"padding-left": "12px"});
			$(".searchinput").fadeOut(200);
     	}
	);
	
	$("#search").hover(
		function(){
			$(this).css({"padding-left": "300px"});
        	$(".searchinput").stop(true, false).delay(200).fadeIn(500);
		},
	 	function(){
			$(this).css({"padding-left": "12px"});
			$(".searchinput").stop(true, false).fadeOut(200);
     	}
	);
		
	$("#country").hover(
		function(){
			$(this).css({"width": "170px"});
			$(this).children("li.norm").stop(true, false).delay(250).fadeIn(800);
		},
	 	function(){
			$(this).css({"width": "74px"});
			$(this).children("li.norm").stop(true, false).fadeOut(30);
     	}
	);
	
	    $(".dropdown").hover(function() {
          $('.dropdown-menu', this).stop( true, true ).fadeIn("fast");
          $(this).toggleClass('open');
          $('b', this).toggleClass("caret caret-up");                
      },
      function() {
          $('.dropdown-menu', this).stop( true, true ).fadeOut("fast");
          $(this).toggleClass('open');
          $('b', this).toggleClass("caret caret-up");                
      }
    );

});

$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
	event.preventDefault();
	$(this).ekkoLightbox();
}); 